import React from "react";
import PropTypes from "prop-types";
import "./ModuleCard.css";

const ModuleCard = ({
    Icon,
    title,
    context,
    actionText,
    navigate,
}) => {
    // jump to our main module page
    const handleJump = () => {
        if(title === "Discover Module"){
            navigate("/");
        } else if (title === "Achieve Module"){
            navigate("/achieve");
        } else if (title === "Drive Module"){
            navigate("/drive");
        }
    }

    return <div className="ModuleCard">
        <div className="iconTitle">
            <Icon size="24" color="#15B79E"/>
        </div>

        <div className="TextArea">
            <span className="title">{title}</span>
            <span className="context">{context}</span>
        </div>

        <span className="actionText" onClick={handleJump}>{actionText}</span>
    </div>
}

ModuleCard.propTypes = {
    Icon: PropTypes.elementType.isRequired,
    title: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    actionText: PropTypes.string.isRequired,
    navigate: PropTypes.func,
}

export default ModuleCard;
