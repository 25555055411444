import React from "react";
import PropTypes from "prop-types";
import StarIcon from "../../icons/StarIcon";
import "./ViewButton.css";

const ViewButton = ({ variant, onClick, isClicked = false, icon: Icon = StarIcon, IconSize = 20, label = "Starred"}) => {
  return (
    <button
      onClick={onClick}
      className={`button-${variant} ${isClicked ? "isClicked" : ""}`}
    >
      <Icon size={IconSize} color={variant === "AI" ? "var(--color-white)" :  "var(--color-gray-700)"}/>
      {label}
    </button>
  );
};

ViewButton.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType,
  label: PropTypes.string,
  IconSize: PropTypes.number,
  isClicked: PropTypes.bool,
};

export default ViewButton;

