// this is the similar issue details componment (windows area) to display the issue slected details
import React, { useState, useCallback } from "react";
import { useAppContext } from "../../../AppContext";
import { generateDynamicStyles } from "../dynamicStyles";
import ResizableDraggablePopup from "./ResizableDraggablePopup";
import { Badge, TabButton, CommentItem } from "../../../Components";
//import { DocxIcon, PdfIcon } from "../../../Components/icons/General";

const IssueDetails = () => {
  const { clickedSimilarIssue, setClickedSimilarIssue, highlightArray, isOpenSpecification } =
    useAppContext();
  const [detailsMenu, setDetailsMenu] = useState("Details");
  const [size, setSize] = useState({ width: 1100, height: "auto" });

  // the cancel button and closed button for closed pop window
  const closeArea = () => {
    //console.log(clickedSimilarIssue);
    setClickedSimilarIssue(null);
  };

  // control the menu format in the Properties area switch
  const switchMenu = useCallback(
    (e) => {
      setDetailsMenu(e.target.value);
    },
    [setDetailsMenu],
  );

  // get all the comments of user clciked issue
  const allComments = clickedSimilarIssue?.["All_Comments"] || [];

  // issueDetailsModal have two insildes function => click mouse to update the new position / relase mosue to stop dragging
  return (
    <div>
      {/* Injecting dynamic styles */}
      <style>
        {generateDynamicStyles(
          highlightArray["Failure Mode"],
          highlightArray["Components"],
          highlightArray["Failure Condition"],
          highlightArray["After Effects"],
          highlightArray["Specification"],
        )}
      </style>

      {/*issueDetailsArea  => click the mouse to start dragging the pop window / and update the position based on the Top and left attribute  */}
      <ResizableDraggablePopup
        onClose={closeArea}
        size={size}
        setSize={setSize}
        isOpenArea={isOpenSpecification}
      >
        <div className="detailsHeader">
          <h2>Issue ID: {clickedSimilarIssue["Issue ID"]} </h2>
          <Badge type={"status"} variant={(clickedSimilarIssue["Status"] === "Closed" || clickedSimilarIssue["Status"] === "Open" || clickedSimilarIssue["Status"] === "Current") ? clickedSimilarIssue["Status"] : "Other"} children={clickedSimilarIssue["Status"]} />
        </div>

        <div className="realativeIssueDetailsMenu">
          <TabButton
            label={"Details"}
            value={"Details"}
            variant={"black"}
            isActive={detailsMenu === "Details" ? true : false}
            onClick={(e) => switchMenu(e)}
          />
          {/* <TabButton
            label={"Timeline"}
            value={"Timeline"}
            variant={"black"}
            isActive={detailsMenu === "Timeline" ? true : false}
            isDisable={true}
            onClick={(e) => switchMenu(e)}
          />
          <TabButton
            label={"Inspect"}
            value={"Inspect"}
            variant={"black"}
            isActive={detailsMenu === "Inspect" ? true : false}
            isDisable={true}
            onClick={(e) => switchMenu(e)}
          /> */}
          <TabButton
            label={"Comments"}
            value={"Comments"}
            variant={"black"}
            isDisable={allComments.length === 0}
            isActive={detailsMenu === "Comments" ? true : false}
            onClick={(e) => switchMenu(e)}
          />
        </div>

        <div style={{ maxHeight: "600px", overflowY: "auto", width: "100%" }}>

          {detailsMenu === "Details" && (
            <div className="issueFormat">
              <div className="issueFormatChild" style={{ marginRight: "4px" }}>

                <div className="issueDetailsRowDisplay">
                  <h3>Date: </h3> {(clickedSimilarIssue["Date"]).split(' ')[0]}
                  {/* <h3 style={{ marginLeft: "16px" }}>Issue ID: </h3> {clickedSimilarIssue["Issue ID"]}  */}
                  <h3 style={{ marginLeft: "16px" }}>Case: </h3> {clickedSimilarIssue["Cases"] ? clickedSimilarIssue["Cases"] : "1"}
                </div>

                <div className="issueDetailsColumnDisplay">
                  <h2>Title </h2>
                  {clickedSimilarIssue ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: clickedSimilarIssue["Title"],
                      }}
                    />
                  ) : (
                    " "
                  )}
                </div>

                {clickedSimilarIssue["Description"] && <div className="issueDetailsColumnDisplay">
                  <h2>Issue Description </h2>
                  {clickedSimilarIssue ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: clickedSimilarIssue["Description"],
                      }}
                    />
                  ) : (
                    " "
                  )}
                </div>}

                <div className="issueDetailsColumnDisplay">
                  <h2>System </h2>
                  <div className="detailsAreaValue">
                    {clickedSimilarIssue && clickedSimilarIssue["AreaList"]
                      ? clickedSimilarIssue["AreaList"].map((area, index) => (
                        <span key={index} className="areaItem">
                          {area}
                        </span>
                      ))
                      : clickedSimilarIssue["Area"]
                        ? clickedSimilarIssue["Area"]
                          .split(", ")
                          .map((area, index) => (
                            <span key={index} className="areaItem">
                              {area}
                            </span>
                          ))
                        : " "
                    }
                  </div>

                  {clickedSimilarIssue["Part Number"] && <div style={{ display: "flex" }}>
                    <h3> Part Number: </h3> {clickedSimilarIssue["Part Number"]}
                  </div>}
                </div>

                {clickedSimilarIssue["Failure Mode"] && <div className="issueDetailsColumnDisplay">
                  <h2>Failure Mode</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: clickedSimilarIssue["Failure Mode"],
                    }}
                  />
                </div>}

                {clickedSimilarIssue["Other"] && <div className="issueDetailsColumnDisplay">
                  <h2>Additional information:</h2>
                  {clickedSimilarIssue["Other"]}
                </div>}

                <div className="issueDetailsRowDisplay">
                  {[
                    clickedSimilarIssue["duplicates"] && (
                      <span key="duplicates">
                        <strong>
                          {clickedSimilarIssue["is_parent"] ? "Is parent of:" : "Duplicates:"}
                        </strong>{" "}
                        {clickedSimilarIssue["duplicates"]}
                      </span>
                    )
                  ]
                    .filter(Boolean)
                    .reduce((acc, item) =>
                      acc.length === 0
                        ? [item]
                        : [...acc, <span key={`sep-${acc.length}`}>&nbsp; &nbsp;</span>, item]
                    , [])}
                </div>

                <div className="issueDetailsRowDisplay">
                  {[
                    clickedSimilarIssue["Requestor"] && (
                      <span key="requestor">
                        <strong>Owner:</strong> {clickedSimilarIssue["Requestor"]}
                      </span>
                    ),
                    clickedSimilarIssue["Creator"] && (
                      <span key="creator">
                        <strong>Creator:</strong> {clickedSimilarIssue["Creator"]}
                      </span>
                    ),
                    clickedSimilarIssue["Reporter"] && (
                      <span key="reporter">
                        <strong>Reporter:</strong> {clickedSimilarIssue["Reporter"]}
                      </span>
                    )
                  ]
                    .filter(Boolean)
                    .reduce((acc, item) => (
                      acc.length === 0
                        ? [item]
                        : [...acc, <span key={`sep-${acc.length}`}>&nbsp; &nbsp;</span>, item]
                    ), [])}
                </div>
              </div>
              <div className="issueFormatChild" style={{ marginRight: "4px" }}>
                {clickedSimilarIssue["Counter measure"] && <div className="issueDetailsColumnDisplay">
                  <h2>Counter measure</h2>
                  {clickedSimilarIssue["Counter measure"]}
                </div>}

                {clickedSimilarIssue["Resolution"] && <div className="issueDetailsColumnDisplay">
                  <h2>Resolution</h2>
                  {clickedSimilarIssue["Resolution"]}
                </div>}

                {clickedSimilarIssue["Quantitative Impact"] && <div className="issueDetailsColumnDisplay">
                  <h2>Financial Impact</h2>
                  {clickedSimilarIssue["Quantitative Impact"] ? clickedSimilarIssue["Quantitative Impact"] : ""}
                </div>}
              </div>


              {/* <div className="issueDetailsColumnDisplay">
                <div style={{ display: "flex", alignItems: "center" }}><h2>Attachments</h2> <p className="downloadText">Download all</p></div>
                <div className="attachmentsArea">
                  <Badge type="file" variant="default" icon={<DocxIcon/>}>
                    Resolutions 6/05.docx
                  </Badge>
                  <Badge type="file" variant="default" icon={<span><PdfIcon/></span>}>
                    Resolutions 6/05.pdf
                  </Badge>
                  <Badge type="file" variant="default" icon={<span><PdfIcon/></span>}>
                    Resolutions 6/05.pdf
                  </Badge>
                </div>
              </div> */}
            </div>
          )}

          {
            detailsMenu === "Comments" && <div className="issueGrayFormat">
              {allComments.map((item, index) => (
                <CommentItem
                  key={index}
                  author={item.author}
                  timestamp={item.timestamp}
                  context={item.content}
                  defaultWidth="calc(100% - 24px)"
                />
              ))}
            </div>
          }
        </div>

      </ResizableDraggablePopup>
    </div>
  );
};

export default IssueDetails;
