/* 
    this is the left area of Module 2 (Search) part: issue menu, issue item and search bar
*/
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useAppContext } from "../../../AppContext";
import IssueDetails from "../../Module Discover/Issue Table/IssueDetails";
import IssueDataGrid from "../../Module Discover/Issue Table/IssueDataGrid";
import "./IssuePart.css";
import { ArrowUp, ArrowDown } from "../../../Components/icons/Arrows";
import { Keyboard } from "../../../Components/icons/General";
import { ToggleSwitch, Badge, Button } from "../../../Components";
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuSeparator,
    DropdownMenuCheckboxItem,
} from "../../../Components/ui/DropdownMenu/DropdownMenu";
import SearchResultIcon from '../../../image/rocket.png';
import {SharedOnlyArea, QueryHeader} from "../../../Components";

const IssuePart = ({ sharedBy }) => {
    const {
        clickedSimilarIssue,
        allColumn,
        selectColumnArray,
        setSelectColumnArray,
        sharedIssueArray,
        highlightArray,
        setHighlightArray,
        setSelectAllHighlight,
        isOpenSpecification,
        setIsOpenSpecification,
        sharedQuery,
    } = useAppContext();


    // the keyboard feature dropdown menu
    const [isKeyBoardOpen, setIsKeyBoardOpen] = useState(false);
    const keyBoardMenuRef = useRef(null); // the ref used for auto closed of dropdown menu

    /**************************************  1. the highlight drop list about words hightlight: ************************************* */
    // select and unselect one highlight item
    const toggleHighlight = (e, key) => {
        e.preventDefault();
        setHighlightArray((prevState) => {
            const newState = {
                ...prevState,
                [key]: !prevState[key],
            };
            // update the main tittle checkbox status based on user selection
            setSelectAllHighlight(Object.values(newState).every(Boolean));
            return newState;
        });
    };

    // the checkBox in the left of highlight, could select all the highlight item
    const handleSelectAll = (e) => {
        e.preventDefault();
        const newSelectAll = true;
        // update the main tittle checkBox
        setSelectAllHighlight(newSelectAll);

        // update all the status of 4 highlight item
        setHighlightArray((prevState) => {
            const newState = {};
            Object.keys(prevState).forEach((key) => {
                newState[key] = newSelectAll;
            });
            return newState;
        });
    };

    //  the checkBox in the left of highlight, click to unSelect all the highlight item
    const handleUnSelectAll = (e) => {
        e.preventDefault();
        setSelectAllHighlight(false);
        // update all the status of 4 highlight item
        setHighlightArray((prevState) => {
            const newState = {};
            Object.keys(prevState).forEach((key) => {
                newState[key] = false;
            });
            return newState;
        });
    };

    // Function: check how many selection item of highlight dropmenu was selected, and the checkbox "Add All" display checked or interminate
    const getSelectionHiglight = (selectedArr) => {
        const checkedCount = Object.values(selectedArr).filter((value) => value === true).length;
        return {
            checkedHighlight: checkedCount > 0 && checkedCount <= Object.keys(selectedArr).length,
            indeterminateHighlight: checkedCount > 0 && checkedCount < Object.keys(selectedArr).length,
        };
    };
    const { checkedHighlight, indeterminateHighlight } = getSelectionHiglight(highlightArray);


    /**************************************  2. Key specification and the Considerations tab button function: ************************************* */
    // open the right area information: key specification and the Considerations
    const hanleOpenSpecification = useCallback(() => {
        setIsOpenSpecification(isOpenSpecification => !isOpenSpecification);
    }, [setIsOpenSpecification]);


    /**************************************  3. The keyboard dropdown menu shows all the keybaord feature: ************************************* */
    // the function: open and closed dropdown menu
    const handleOpenKeyboardMenu = () => {
        setIsKeyBoardOpen(!isKeyBoardOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (keyBoardMenuRef.current && !keyBoardMenuRef.current.contains(event.target)) {
                setIsKeyBoardOpen(false);
            }
        };

        document.addEventListener("pointerdown", handleClickOutside);
        return () => document.removeEventListener("pointerdown", handleClickOutside);
    }, []);


    /**************************************  4. The Customize Table Function Part: ************************************* */
    // Handle column selection change, save the user selected the column to display
    const handleColumnChange = (e, column) => {
        e.preventDefault();
        setSelectColumnArray((prev) => {
            let newColumns;
            if (prev.some((item) => item.field === column)) {
                // if already selected, second clicked means unselected
                newColumns = prev.filter((c) => c.field !== column);
            } else {
                // selected to add into seleected column
                newColumns = [
                    ...prev,
                    {
                        field: column,
                        headerName: column,
                        width: 200,
                        minWidth: 200,
                    },
                ];
            }
            return newColumns;
        });
    };

    // the "Selected all " function used for add all the column to display
    const handleSelectAllColumn = (e) => {
        e.preventDefault();
        setSelectColumnArray((prev) => {
            // Get the list of columns that are not yet selected
            const unselectedColumns = allColumn.filter(
                (column) => !prev.some((item) => item.field === column),
            );

            // Add only the unselected columns to the array
            const newColumns = unselectedColumns.map((column) => ({
                field: column,
                headerName: column,
                width: 200,
                minWidth: 200,
            }));

            // Return the previous selected columns along with the newly selected ones
            return [...prev, ...newColumns];
        });
    };

    // unSelected function for removed all the selection of drop down selection list
    const handleUnSelectAllColumn = (e) => {
        e.preventDefault();
        setSelectColumnArray(() => []); // Clear all selected columns
    };

    // check how many columns selected ==> whether it equals the all the columns
    const getSelectionState = (selectedArr) => {
        const checkedCount = selectedArr.length;
        return {
            checked: checkedCount > 0 && checkedCount <= allColumn.length,
            indeterminate: checkedCount > 0 && checkedCount < allColumn.length,
        };
    };
    const { checked, indeterminate } = getSelectionState(selectColumnArray);


    // main render Area with HTML Tag
    return (
        <div className="shareArea"
            style={{
                width: isOpenSpecification ? 'calc(68vw - 25px)' : '100%',
                transition: 'width 0.5s ease',
            }}
        >
            <SharedOnlyArea
                title="Share-read only link"
                context="Anyone with the link can view the site in Apiphany.ai but won’t be able to edit it"
                contactText="Contact us"
                defaultWidth="calc(100% - 32px)"
            />
            <div className="controlArea">
                {
                    sharedIssueArray.length > 0 ? <p className="searchResultMessage"> <img src={SearchResultIcon}
                        alt="SearchResultIcon"
                        style={{ height: "20px", width: "20px", marginRight: "6px" }}
                    />
                        {sharedIssueArray.length} similar issues shared for you
                    </p> : <p></p>
                }

                <div style={{ display: "flex" }}>
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <span className="dropdown-menu-trigger-text">Highlights</span>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuCheckboxItem
                                checked={checkedHighlight}
                                indeterminate={indeterminateHighlight}
                                onClick={handleSelectAll}
                            >
                                Select all
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuCheckboxItem checked={highlightArray["Failure Mode"]} onClick={(e) => toggleHighlight(e, "Failure Mode")}>
                                <Badge type="highlight" variant="failure-mode">
                                    Failure Mode
                                </Badge>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem checked={highlightArray["Components"]} onClick={(e) => toggleHighlight(e, "Components")}>
                                <Badge type="highlight" variant="components">
                                    Components
                                </Badge>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem checked={highlightArray["Failure Condition"]} onClick={(e) => toggleHighlight(e, "Failure Condition")}>
                                <Badge type="highlight" variant="failure-condition">
                                    Failure Condition
                                </Badge>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem checked={highlightArray["After Effects"]} onClick={(e) => toggleHighlight(e, "After Effects")} >
                                <Badge type="highlight" variant="after-effects">
                                    After Effects
                                </Badge>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuCheckboxItem checked={highlightArray["Specification"]} onClick={(e) => toggleHighlight(e, "Specification")} >
                                <Badge type="highlight" variant="specifications">
                                    Specification
                                </Badge>
                            </DropdownMenuCheckboxItem>
                            <DropdownMenuSeparator />

                            <div style={{ marginLeft: "2px" }}>
                                <Button variant="danger-link" size="medium" onClick={handleUnSelectAll}>
                                    Clear all
                                </Button>
                            </div>
                        </DropdownMenuContent>
                    </DropdownMenu>

                    <div className="ToggleButton">
                        <p>Key Specifications</p>
                        <ToggleSwitch isOpen={isOpenSpecification} onToggle={hanleOpenSpecification} width="36px" height="18px" />
                    </div>
                </div>
            </div>


            <div className="sharedItemArea">
                <div className="rightButtonArea">
                    {/* the keyboard shows feature */}
                    <div className="keyBoardButton" ref={keyBoardMenuRef} onClick={handleOpenKeyboardMenu}>
                        <Keyboard />
                    </div>
                    <div className={`keyboardDropdownMenu ${isKeyBoardOpen ? "show" : ""}`}>
                        <div className="featureItem"> Move up  <div className="KeyBoardIcon"> <ArrowUp size={20} color="#667085" /> </div> </div>
                        <div className="featureItem"> Move Down  <div className="KeyBoardIcon"> <ArrowDown size={20} color="#667085" /> </div> </div>
                        <div className="featureItem"> Mark selected row checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> space bar </div> </div>
                        <div className="featureItem"> Selected all the rows checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+A </div> </div>
                        <div className="featureItem"> UnSelected all the rows checkbox <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+D </div> </div>
                        <div className="featureItem"> Open selected row details window  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Enter </div> </div>
                        <div className="featureItem"> Mark current row as favorite <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Ctrl+S </div> </div>
                        <div className="featureItem"> Move Five Rows up  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> PageUp </div> </div>
                        <div className="featureItem"> Move Five Rows Down  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> PageDown </div> </div>
                        <div className="featureItem"> Move to First Issue  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> Home </div> </div>
                        <div className="featureItem"> Move to Last Issue  <div className="KeyBoardIcon" style={{ color: "var(--color-gray-500" }}> End </div> </div>
                    </div>

                    {/* the customize table button: the dropdown menu to add the columns of issue table */}
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <span className="dropdown-menu-trigger-text">Customize Table</span>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>

                            <DropdownMenuCheckboxItem
                                checked={checked}
                                onSelect={handleSelectAllColumn}
                                indeterminate={indeterminate}
                            >
                                Add all
                            </DropdownMenuCheckboxItem>

                            <DropdownMenuSeparator />
                            <div className="filterMenuScrollable">
                                {allColumn.map((column) => (
                                    <DropdownMenuCheckboxItem
                                        key={column}
                                        checked={selectColumnArray.some(
                                            (item) => item.field === column,
                                        )}
                                        onClick={(e) => handleColumnChange(e, column)}
                                    >
                                        {column}
                                    </DropdownMenuCheckboxItem>
                                ))}
                            </div>
                            <DropdownMenuSeparator />

                            <div style={{ marginLeft: "2px" }}>
                                <Button variant="danger-link" size="medium" onClick={handleUnSelectAllColumn}>
                                    Clear all
                                </Button>
                            </div>
                        </DropdownMenuContent>
                    </DropdownMenu>

                </div>
            </div>


            {/* The Issue Data Table: Display all the issue datas */}
            <div className="shareIssueAreaFormat">
                <div className="shareQueryDeatils">
                    <div className="queryArea">
                        <QueryHeader
                            queryArray={sharedQuery}
                            timestamp={"00/00/2024 at 00:00 PM"}
                            defaultWidth={"calc(100% - 36px)"}
                            isSharedViews={true}
                            fromUserName={sharedBy.name}
                            fromUserEmail={sharedBy.email}
                        />

                    </div>

                    {/* the issue data grid about all the issue detail */}
                    <IssueDataGrid tableViews={"Shared"}/>
                </div>

    
            </div>

            {/* open the relative issue details pop window */}
            {clickedSimilarIssue !== null && <IssueDetails />}


        </div>
    );
};

export default IssuePart;
