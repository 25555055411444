import React, { useState } from "react";
import './layout.css'
//import { useAppContext } from "../../../AppContext";
import { useNavigate } from "react-router-dom";
import { FormHeader } from "../../../index";
import { InputFields, Button } from "../../../Components";
import companyLogoWithoutName from "../../../image/ApiphanyLogo/ApiphanyIconWithoutName.jpg";
//import useAuthStore from "../../../stores/useAuthStore";
import ModuleImage from "../../../image/ApiphanyLogo/Mockup modules.png";
import { ArrowLeft } from "../../../Components/icons/Arrows";
import { useSendEmailMutation } from "../../../api/queries";
import { SuccessNotice } from "../../../Components";
import { Check } from "../../../Components/icons/General";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState(""); // the email that user input for logIn or change password
    const [errorMessage, setErrorMessage] = useState(""); // the error message for check email is vaild or not
    const [emailVaild, setEmailValid] = useState(false);

    const checkEmailMutation = useSendEmailMutation();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation

    const handleInputChange = (e) => {
        if (emailRegex.test(email) && errorMessage !== "") {
            setErrorMessage("")
        } 
        setEmail(e.target.value);
    }

    // add the backend api in here ==> send it into backend
    const handleCheckEmail = () => {
        if (!emailRegex.test(email)) {
            setErrorMessage("This email is not vaild");
            setEmailValid(false);
        } else{
            checkEmailMutation.mutate(email, {
                onSuccess: () => {
                    setErrorMessage("");
                    setEmailValid(true);

                    // Navigate after successful reset request
                   setTimeout(() => {
                    navigate('/login');
                    }, 5000);
                },
                onError: () => {
                    setErrorMessage("Can not found");
                    setEmailValid(true);
                    setTimeout(() => {
                        navigate('/login');
                    }, 5000);
                }
            });
        }
    }

    const handleKeyBoardEnter = (e) => {
        if (e.key === 'Enter') { // Check if the pressed key is Enter
            e.target.blur(); // jump to outside of active input

            if (!emailRegex.test(email)) {
                setErrorMessage("This email is not vaild");
                setEmailValid(false);
            } else {
                checkEmailMutation.mutate(email, {
                    onSuccess: () => {
                        setErrorMessage("");
                        setEmailValid(true);

                        // Navigate after successful reset request
                        setTimeout(() => {
                            navigate('/login');
                        }, 5000);
                    },
                    onError: () => {
                        setErrorMessage("Can not found");
                        setEmailValid(true);
                        setTimeout(() => {
                            navigate('/login');
                        }, 5000);
                    }
                });
            }
        }
    }
      

    // the jumptext back to login page
    const handleBackToLogIn = () => {
        navigate('/login');
    }

    return <div className="container">

        <div className="forgotPasswordComp">
            {emailVaild ? <SuccessNotice
                title="Email Sent"
                Icon={Check}
                subTitle="If your email is in our system, a password reset link will arrive in your inbox shortly."
            /> :
                <div className="forgetPasswordArea">
                    <FormHeader
                        title="Reset password"
                        subtitle="Enter your email and we'll send you a link to reset your password"
                        className="forgotHeader"
                        companyImage={companyLogoWithoutName}
                    />

                    <InputFields
                        height="24px"
                        hint=""
                        isRequired={true}
                        label="Email"
                        placeholder="Enter your email"
                        type={(errorMessage && errorMessage === "This email is not vaild") ? "errorWithBlack" : "generalWithBlack"}
                        hasIcon={(errorMessage && errorMessage === "This email is not vaild") ? true : false}
                        errorMessage={(errorMessage && errorMessage === "This email is not vaild") ? "This email is not vaild" : ""}
                        width="334px"
                        value={email}
                        onKeyDown={handleKeyBoardEnter}
                        onChange={handleInputChange}
                        isPassword={false}
                    />

                    <Button
                        variant="primary"
                        size="medium"
                        width={"100%"}
                        onClick={handleCheckEmail}
                        disabled={(errorMessage && errorMessage === "This email is not vaild") ? true : false}
                    >
                        Send link to email
                    </Button>

                    <span className="jumpText" onClick={handleBackToLogIn}> <ArrowLeft size={14} color="var(--blue-600)" />  Back to log in page  </span>
                </div>
            }
        </div>

        <div className="image-container">
            <img src={ModuleImage} alt="All Module" className="imageBig" />
        </div>
    </div>
}

export default ForgotPassword;