import "./App.css";
import "./CSS Global Style/global.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import companyLogoWithName from "./image/ApiphanyLogo/ApiphanyIconWithName.jpg";
import { AppProvider } from "./AppContext";
import Navigation from "./Components/layout/Application Navigation/NavigationBar";
import DiscoverIcon from "./Components/icons/General/DiscoverModule";
import DriveIcon from "./Components/icons/General/DriveModule";
import AchieveIcon from "./Components/icons/General/AchieveModule";
import { routes } from "./routes/routes";
import AuthRoute from "./AuthRoute/AuthRoute";
import useAuthStore from "./stores/useAuthStore";
import MiniumWidth from "./Pages/Global Component/Minium Width/MiniumWidth";

const AppContent = () => {
  const { isAuthenticated, user } = useAuthStore();
  const [showMinWarning, setShowMiniWarning] = useState(false);
  const location = useLocation();

  const menuItems = [
    { name: "Discover", key: "discover", icon: <DiscoverIcon /> },
    { name: "Drive", key: "drive", icon: <DriveIcon /> },
    { name: "Achieve", key: "achieve", icon: <AchieveIcon /> },
  ];

  // useEffect to listener the website page width change
  useEffect(() => {
    // check the screen width happened change
    const handleResize = () => {
      setShowMiniWarning(window.innerWidth <= 1024);
    };

    handleResize(); // restart the check width function

    // listener the screen width 
    window.addEventListener('resize', handleResize);

    // clear the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      window.analytics.identify(user?.email, {
        name: `${user?.firstName} ${user?.lastName}`,
        email: user?.email,
      });
    }
  }, [isAuthenticated, user]);

  // only Include Amplitude Session on the front end production deployment part
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const isScriptLoaded = !!document.querySelector(
        'script[src="https://cdn.amplitude.com/libs/analytics-browser-2.11.1-min.js.gz"]'
      );

      if (!isScriptLoaded) {
        const amplitudeScript = document.createElement('script');
        amplitudeScript.src = 'https://cdn.amplitude.com/libs/analytics-browser-2.11.1-min.js.gz';
        document.head.appendChild(amplitudeScript);

        const sessionReplayScript = document.createElement('script');
        sessionReplayScript.src = 'https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.8.0-min.js.gz';
        document.head.appendChild(sessionReplayScript);

        amplitudeScript.onload = () => {
          window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1 }));
          window.amplitude.init('a15d98ad6eaccd49a428db1388c18a32');
        };
      }
    }
  }, []);

  // the main render and route setup
  return (
    <>
      <Toaster
        position="top-center"
        containerStyle={{
          marginTop: '40px', // the distance of screen top
        }}
        toastOptions={{
          duration: 3000,
          style: {
            fontFamily: 'var(--font-family-body)',
            fontSize: 'var(--font-size-md)',
            zIndex: 1000000,
            padding: '12px 16px',
          },
          success: {
            style: {
              background: 'var(--success-50)',
              color: 'var(--success-700)',
            },
          },
          error: {
            style: {
              background: 'var(--danger-50)',
              color: 'var(--danger-700)',
            },
          },
        }}
      />
      {showMinWarning && <MiniumWidth showMinWarning={showMinWarning} />}

      {isAuthenticated && location.pathname !== '/404Error' && (
        <Navigation
          menuItems={menuItems}
          isLoggedIn={isAuthenticated}
          companyLogo={companyLogoWithName}
          loginUser={user}
        />
      )}

      <div className="layout-container">
        <div className="content">
          <Routes>
            {routes.map(
              ({ path, element, protected: isProtected }, index) => {
                const Component = isProtected ? (
                  <AuthRoute>{element}</AuthRoute>
                ) : (
                  element
                );

                return (
                  <Route key={index} path={path} element={Component} />
                );
              },
            )}
          </Routes>
        </div>
      </div>
    </>
  );
};

// the main render area (App.js)
function App() {
  return (
    <Router>
      <AppProvider>
        <div className="App">
          <AppContent />
        </div>
      </AppProvider>
    </Router>
  );
}


export default App;
