import axios from "axios";
import { useNavigate } from 'react-router-dom';

//const baseURL = process.env.REACT_APP_API_URL + "/api";
const baseURL = "https://staging.api.apiphany.ai/api";
const api = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// stop the error, and jump to the error page
// api.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response) {
//       const navigate = useNavigate();
//       if (error.response.status === 403 || error.response.status === 404) {
//         // jump to the error page
//         navigate('/404Error');
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export default api;
