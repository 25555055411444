import React from "react";
import PropTypes from "prop-types";
import { IconsMisc } from "../../icons";
import "./SharedOnlyArea.css";
import { AlertCircle } from "../../icons/Alerts";

const SharedOnlyArea = ({ title, context, contactText, defaultWidth, }) => {
    return (
        <div className="sharedOnly" style={{ width: defaultWidth }}>

            <div className="iconArea">
                <IconsMisc
                    size="md"
                    color="red"
                    Icon={AlertCircle}
                />
            </div>

            <div className="titleArea">
                <div style={{display: "flex", flexDirection: "column", gap: "4px"}}>
                    <span className="titleLayout">{title}</span>
                    <span className="subText">{context}</span>
                </div>
                <span className="contactText">{contactText}</span>
            </div>
        </div>
    );
};

SharedOnlyArea.propTypes = {
    title: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    contactText: PropTypes.string.isRequired,
    defaultWidth: PropTypes.string,
};
 

export default SharedOnlyArea;
