import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "./config";
import useAuthStore from "../stores/useAuthStore";
import { mapIssueFields } from '../config/issueMapping';
import toast from 'react-hot-toast';


// the logIn feature ==> call API 
export const useLoginMutation = () => {
  return useMutation(async (credentials) => {
    const response = await api.post("/token", credentials);
    return response.data;
  });
};

// the all the issue Id ==> call API
export const useAllIssueID = () => {
  return useQuery('allIssueIds', async () => {
    const token = useAuthStore.getState().token;
    const response = await api.get('/get-all-ids', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });  
    return response.data;
  });
};

// the search relative issue api and get the key specification area data
export const useSearchMutation = () => {
  return useMutation(async (ticketId) => {
    const token = useAuthStore.getState().token;
    const response = await api.post(
      "search",
      { query: ticketId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

// the favorite function to save user selected favorite
export const useToggleFavoriteMutation = () => {
  return useMutation(async ({ issueId, sourceId, isFavorite }) => {
    const token = useAuthStore.getState().token;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (isFavorite) {
      const response = await api.delete("/favorites", {
        ...config,
        data: { issue_id: issueId, source_id: sourceId },
      });
      return response.data;
    } else {
      const response = await api.post(
        "/favorites",
        { issue_id: issueId, source_id: sourceId },
        config,
      );
      return response.data;
    }
  });
};

// the update password api function
export const useUpdatePasswordMutation = () => {
  return useMutation(async (passwordData) => {
    const token = useAuthStore.getState().token;
    const response = await api.post("/update-password",
      {
        current_password: passwordData.currentPassword,
        password: passwordData.newPassword,
        password_confirmation: passwordData.confirmPassword
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

// Hook used to share user selected issue ID
export const useShareIssuesMutation = () => {
  return useMutation(async ({ emails, issueIds, query }) => {
    // Validate emails
    if (!emails || !Array.isArray(emails) || emails.length === 0) {
      throw new Error('At least one email is required');
    }

    // Validate issueIds
    if (!issueIds || !Array.isArray(issueIds) || issueIds.length === 0) {
      throw new Error('At least one issue ID is required');
    }

    // Validate query is a string if provided
    if (query !== null && typeof query !== 'string') {
      throw new Error('Query must be a string');
    }

    try {
      const token = useAuthStore.getState().token;
      
      const response = await api.post(
        "/share",
        { 
          emails: emails,
          issue_ids: issueIds,
          query: query || null
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      return response.data;
      
    } catch (error) {
      console.error('Share error:', error);
      throw error.response?.data?.message || error.message || 'Failed to share issues';
    }
  });
};

// Hooks to update user eidted the field (column system)
export const useUpdateIssueMutation = () => {
  return useMutation(async ({ issueId, field, value }) => {
    const token = useAuthStore.getState().token;
    const response = await api.post(
      "/update-issue",
      { 
        issue_id: issueId,
        field: field,
        value: value 
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  });
};

// Hook for fetching saved issues
export const useSavedIssuesQuery = () => {
  return useQuery(
    'savedIssues',
    async () => {
      const token = useAuthStore.getState().token;
      const savedResponse = await api.get("/saves", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!savedResponse.data?.saves || savedResponse.data.saves.length === 0) {
        return [];
      }

      const issueIds = savedResponse.data.saves.map((save) => save.issue_id).join(',');

      const issuesResponse = await api.get(`/search/issues?issuelist=${issueIds}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return issuesResponse.data.data.map((issue) => mapIssueFields(issue));
    },
    {
      staleTime: Infinity, // Data is always fresh
      cacheTime: Infinity, // Cache data indefinitely
      refetchOnMount: true, // Refetch on component mount
      refetchOnWindowFocus: false, // Do not refetch on window focus
    }
  );
};

// Hook for saving issues
export const useSaveIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = useAuthStore.getState().token;
      const response = await api.post(
        "/saves",
        { 
          issue_ids: issueIds,
          source_id: sourceId 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('savedIssues');
        toast.success(
          `${data.saved_count} issues saved successfully${data.duplicate_count ? ` (${data.duplicate_count} duplicates)` : ''}`
        );
      },
      onError: () => {
        toast.error('Failed to save issues');
      },
    }
  );
};

// Hook for deleting saved issues
export const useDeleteSavedIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (issueIds) => {
      const token = useAuthStore.getState().token;
      const response = await api.delete("/saves", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { issue_ids: issueIds }
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('savedIssues');
        toast.success('Issues removed from saved successfully');
      },
      onError: () => {
        toast.error('Failed to remove issues from saved');
      },
    }
  );
};

// Hook for fetching hidden issues
export const useHiddenIssuesQuery = () => {
  return useQuery(
    'hiddenIssues',
    async () => {
      const token = useAuthStore.getState().token;
      const hiddenResponse = await api.get("/hides", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!hiddenResponse.data?.hides || hiddenResponse.data.hides.length === 0) {
        return [];
      }

      const issueIds = hiddenResponse.data.hides.map((hide) => hide.issue_id).join(',');

      const issuesResponse = await api.get(`/search/issues?issuelist=${issueIds}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return issuesResponse.data.data.map((issue) => mapIssueFields(issue));
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
};

// Hook for hiding issues
export const useHideIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ issueIds, sourceId }) => {
      const token = useAuthStore.getState().token;
      const response = await api.post(
        "/hides",
        { 
          issue_ids: issueIds,
          source_id: sourceId 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('hiddenIssues');
        toast.success(
          `${data.hidden_count} issues hidden successfully${data.duplicate_count ? ` (${data.duplicate_count} duplicates)` : ''}`
        );
      },
      onError: () => {
        toast.error('Failed to hide issues');
      },
    }
  );
};

// Hook for unhiding issues
export const useUnhideIssuesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (issueIds) => {
      const token = useAuthStore.getState().token;
      const response = await api.delete("/hides", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { issue_ids: issueIds }
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('hiddenIssues');
        toast.success('Issues unhidden successfully');
      },
      onError: () => {
        toast.error('Failed to unhide issues');
      },
    }
  );
};


/* the connect backend function to update the password */
// 1. user input their account email, and create the token send to user email 
export const useSendEmailMutation = () => {
  return useMutation(
    async (email) => {
      const response = await api.post("/reset-password", { email });
      return response.data;
    },
    // {
    //   onSuccess: (data) => {
    //     toast.success("Reset password link has been sent to your email");
    //   },
    // }
  );
};

// 2. get the token and check token is correct or not
export const useVerifyResetTokenMutation = () => {
  return useMutation(
    async (token) => {
      const response = await api.get(`/reset-password/${token}`, { token });
      return response.data;
    },
    // {
    //   onSuccess: (data) => {
    //     toast.success("Token is valid.");
    //   },
    //   onError: (error) => {
    //     toast.error(error.response?.data?.message || "Invalid or expired token.");
    //   },
    // }
  );
};

 
// 3. send user input email and token to backend: update the password
export const useResetPasswordMutation = () => {
  return useMutation(
    async ({ token, password, password_confirmation }) => {
      const response = await api.post(`/reset-password/${token}`, {
        password,
        password_confirmation,
      });
      return response.data;
    },
    // {
    //   onSuccess: () => {
    //     toast.success("Password has been reset successfully.");
    //   },
    //   onError: (error) => {
    //     toast.error(error.response?.data?.message || "Failed to reset password.");
    //   },
    // }
  );
}


// Hook for fetching organization users
export const useOrganizationUsersQuery = () => {
  return useQuery(
    'organizationUsers',
    async () => {
      const token = useAuthStore.getState().token;
      const response = await api.get("/organization/users/emails", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false, 
    }
  );
};

// Hook for fetching shared issues
export const useSharedIssuesQuery = () => {
  return useQuery(
    'sharedIssues',
    async () => {
      const token = useAuthStore.getState().token;
      const response = await api.get("/share", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data?.data || response.data.data.length === 0) {
        return [];
      }

      return response.data.data.map((issue) => mapIssueFields(issue._source, true));
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useStarredIssuesQuery = () => {
  return useQuery(
    'starredIssues',
    async () => {
      const token = useAuthStore.getState().token;
      const starredResponse = await api.get("/favorites", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!starredResponse.data?.data || starredResponse.data.data.length === 0) {
        return [];
      }

      return starredResponse.data.data.map((issue) => mapIssueFields(issue._source, true));
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
};

// Hooks: used the token to get all the shared informtion (query, shared issue array, sharedBy email & name)==> share viewer module page
export const useSharedContentQuery = (token) => {
  return useMutation(
    async () => {
      try {
        const authToken = useAuthStore.getState().token;
        const response = await api.get(`/shared/${token}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const data = response.data;
        return {
          issues: data.issues.map(issue => mapIssueFields(issue)),
          query: data.query,
          sharedBy: data.shared_by
        };
      } catch (error) {
        if (error.response?.status === 404) {
          throw new Error('Invalid token');
        }
        throw error;
      }
    }
  );
};

// Key Specification Data Card Share Feature function:
// 1. the share function with create the token to email user selected 
export const useShareSpecs = () => {
  return useMutation(
    async ({ values, current_issue_id, emails }) => {
      // Validate emails
      if (!emails || !Array.isArray(emails) || emails.length === 0) {
        throw new Error('At least one email is required');
      }

      // currentIssueId array is string
      if (current_issue_id !== null && typeof current_issue_id !== 'string') {
        throw new Error('Value must be an string');
      }

      // Validate array is required array
      if (values !== null && !Array.isArray(values)) {
        throw new Error('Value must be an array');
      }
      console.log(current_issue_id);
      try {
        const authToken = useAuthStore.getState().token;

        const response = await api.post(
          '/share-specs',
          {
            values: values,
            current_issue_id: current_issue_id,
            emails: emails,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        return response.data; // if success with no errors, will return information with share_id
      } catch (error) {
        if (error.response?.data?.error) {
          throw new Error(error.response.data.error);
        }
        throw error;
      }
    }
  );
};

// 2. used the token to get all the information
// the value array including:
// 1. specificationsMenu: Issue Insights or Key Specs
// 2. selectIssueDetails: current issue (issue ID) and the Related Issues
// 3. subKeySpecsMenu: Design Docs, Standards and the Scholar Search
// 4. the subsystem tittle
export const useSharedSpecsQuery = (token) => {
  return useMutation(
    async () => {
      try {
        const authToken = useAuthStore.getState().token;
        const response = await api.get(`/sharedspecs/${token}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const data = response.data;
        // if sucess return all share information, value, current issue ids, and sharedBy
        return {
          values: data.values, // shared values array
          currentIssueId: data.current_issue_id, // current issue id
          sharedBy: data.shared_by, // shared by info
        };
      } catch (error) {
        if (error.response?.status === 404) {
          throw new Error('Invalid token');
        }
        throw error;
      }
    }
  );
};
