import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import IssuePart from "./Issue Part/IssuePart";
import SpecificationArea from "../Module Discover/Key Specifications/SpecificationArea";
import { useSharedContentQuery } from '../../api/queries';
import { useAppContext } from "../../AppContext";
import toast from 'react-hot-toast';
import "./SharedView.css"

const SharedView = () => {
  const { "*": token } = useParams();
  const navigate = useNavigate(); // jump page
  const { 
    setSharedIssuedArray,
    sharedBy, 
    setSharedBy,
    setIsSharedView,
    setSharedQuery,
  } = useAppContext();
  
  // call the backend function: to check current URL token
  const sharedContentMutation = useSharedContentQuery(token);

  // use the backend function to check current URL token was correct or not
  useEffect(() => {
    if (!token) {
      navigate('/404Error');
      return;
    }

    sharedContentMutation.mutate(undefined, {
      onSuccess: (data) => {
        console.log(data);
        setSharedIssuedArray(data.issues);
        setSharedBy(data.sharedBy);
    
        if (data.query) {
          // based on the space split the string to save in the array
          const queryArray = data.query.split(' ');
        
          // travser all the sub-item, if some item all integer should add 'issue id'
          const updatedArray = queryArray.map((item) =>
            /^\d+$/.test(item) ? `Issue ID: ${item}` : item
          );
        
          // saved it into the display query
          setSharedQuery(updatedArray);
        }
        
        setIsSharedView(true);
      },
      onError: () => {
       //toast.error('Invalid token');
        navigate('/404Error');
      }
    });
  }, // eslint-disable-next-line 
  [token]);

  // if the data was still loading, run the loading animation
  if (sharedContentMutation.isLoading) {
    return (
      <div className="fade-in">
        <div className="dots-loader">
          {Array.from({ length: 16 }).map((_, index) => (
            <div
              key={index}
              className="dot"
              style={{
                transform: `rotate(${index * 22.5}deg) translateY(-20px)`,
                animationDelay: `${index * 0.125}s`
              }}
            />
          ))}
        </div>
        <p style={{ marginLeft: "8px" }}>Loading shared issues...</p>
      </div>
    );
  }


  //  main render area
  return (

    <div className="shareView">
      <IssuePart sharedBy={sharedBy}/>

      <SpecificationArea/>
    </div>
  );
};

export default SharedView; 