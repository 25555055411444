// this is the query details will using for 
// 1. Shared Saved Hideden and Starred Button
// 2. the Shared (read-only) page for other user read
import React from "react";
import PropTypes from "prop-types";
import "./QueryHeader.css";
import { Badge } from "../../ui/Badge";

const QueryHeader = ({
    timestamp,
    queryArray = [],
    teamArray,
    isShared = false, // shared to someone layout
    isSharedViews = false, // get this from some one layout
    fromUserName,
    fromUserEmail,
    defaultWidth = "650px", 
}) => {
    return (
        <div className="queryHeaderArea" style={{ width: defaultWidth }}>
            <div className="queryDetails">
                <div className="queryList">
                    <span className="title"> Query: </span>
                    {queryArray.map((item, index) => (
                        <Badge key={index} type="defaultGray">
                            {/^\d+$/.test(item) === true ? `Issue id: ${item}` : item}
                        </Badge>
                    ))}
                </div>
                <span className="subText">{timestamp}</span>
            </div>

            {(isSharedViews && !isShared) && <div className="queryDetails">
                <p className="sharedTitle" style={{marginTop: "3px"}}>Shared by:</p>
                <p className="subText" style={{marginTop: "3px"}}>{fromUserName} <a className="emailText">{fromUserEmail}</a> </p>
            </div>}
        </div>
    );
};

QueryHeader.propTypes = {
    timestamp: PropTypes.string.isRequired,
    queryArray: PropTypes.array.isRequired,
    teamArray: PropTypes.array,
    isShared: PropTypes.bool,
    isSharedViews: PropTypes.bool,
    fromUserName: PropTypes.string,
    fromUserEmail: PropTypes.string,
    defaultWidth: PropTypes.string,
};

export default QueryHeader;