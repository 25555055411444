// Module 2 (Discover) page: looks up the issue to see if it has occurred before and gets a few ideas of how to solve and where the considerations are.
import React, { useEffect } from "react";
import "./discover.css";
import IssueArea from "./Issue Table/IssueArea";
import issueInfo from "../../datasets/data_cleaning/Discover Module Data/discover_v1.json";
import SpecificationArea from "./Key Specifications/SpecificationArea";
import { useSharedSpecsQuery, useSearchMutation} from "../../api/queries";
import toast from 'react-hot-toast';
import {useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppContext } from "../../AppContext";
import { mapIssueFields } from '../../config/issueMapping';

// 1. share the key specification data card
// the value array including:
// 1. specificationsMenu: Issue Insights or Key Specs
// 2. selectIssueDetails: current issue (issue ID) and the Related Issues
// 3. subKeySpecsMenu: Design Docs, Standards and the Scholar Search
// 4. the subsystem tittle
const Discover = () => {
  const { "*": token } = useParams() || null;
  //console.log(token); // check get the token

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParam = searchParams.get("query") || "";

  const navigate = useNavigate(); // jump page
  const sharedSpeciContentMutation = useSharedSpecsQuery(token);
  const searchMutation = useSearchMutation();
  const {setBasicValue, setSpecificationsMenu, setSubKeySpecsMenu, setSelectIssueDetails, setOpenItem, setIsOpenSpecification,
    setClickedIssue, setIssueRelativeArray, setStagesArray, setFilterOptions, setClickedSimilarIssue, setSelectedMenuItem,
    setIsSearchError, setModalMessage, setIsSearchLoading, setShowsStarIssue, setTableViews, setSpecData
  } = useAppContext();

  // use the backend function to check current URL token was correct or not
  useEffect(() => {
    setSelectedMenuItem("discover");
    if (token && !queryParam) {
      sharedSpeciContentMutation.mutate(undefined, {
        onSuccess: (data) => {
          // console.log(data);
          const basicVal = [{
            id: data.currentIssueId,
            value: data.currentIssueId,
            isIssueId: !isNaN(Number(data.currentIssueId)),
          }];
          setBasicValue(basicVal);
          setSpecificationsMenu(data.values[0]);
          setSubKeySpecsMenu(data.values[1]);
          setSelectIssueDetails(data.values[2]);
          setOpenItem([data.values[3]]);
          setIsOpenSpecification(true);
          fetchData(basicVal); // call the search API function and get current shared issue id data

          // call the search API function
          async function fetchData(basicVal) {
            if (basicVal.length > 0 && Array.isArray(basicVal)) {
              setIsSearchLoading(true);
              setShowsStarIssue(false);
              setTableViews("All");

              try {
                const id = parseInt(basicVal[0].id, 10);
                if (isNaN(id)) {
                  setModalMessage("Invalid input. Please provide a valid Issue ID.");
                  setIsSearchError(true);
                  return;
                }
                const result = await searchMutation.mutateAsync(id);
                if (result.original_issue) {
                  const updatedIssueItem = mapIssueFields(result.original_issue, true);
                  setClickedIssue(updatedIssueItem); // set the original_issue part as currect issue (clicked issue)
                  setClickedSimilarIssue(updatedIssueItem); // set th shared current issue to open deatils pop windows

                  // used our map route to make the attrubite as data column to display
                  const mappedRelatedIssues = Array.isArray(result.related_issues)
                    ? result.related_issues.map((issue) => mapIssueFields(issue))
                    : [];
                  setIssueRelativeArray(mappedRelatedIssues); // setup the issue data rows 

                  // get all stage item used for stage Tab menu
                  const stagesArr = [
                    "All",
                    ...new Set(mappedRelatedIssues.map((issue) => issue.Phase)),
                  ]
                    .filter(Boolean)
                    .sort();
                  setStagesArray(stagesArr);
                  setSpecData(result.specs || []);

                  // get all the filter select option used for search filed fiter menu
                  const extractedFilters = {
                    status: [
                      ...new Set(
                        result.related_issues
                          .filter((issue) => issue._source?.Status != null)
                          .map((issue) => issue._source.Status),
                      ),
                    ],
                    system: [
                      ...new Set(
                        result.related_issues
                          .filter((issue) => issue?._source["System"] != null)
                          .map((issue) => issue._source["System"].split(","))
                          .flat()
                          .map((str) => str.trim()),
                      ),
                    ],
                    requestor: [
                      ...new Set(
                        result.related_issues
                          .filter((issue) => issue._source?.Assignee != null)
                          .map((issue) => issue._source.Assignee),
                      ),
                    ],
                  };
                  setFilterOptions(extractedFilters);
                } else {
                  setModalMessage(
                    "Sorry, cannot find this input number, please try to input another Issue Number or Part Number",
                  );
                  setIsSearchError(true);
                  setClickedIssue(null);
                  setIssueRelativeArray([]);
                  setStagesArray(["All"]);
                }
              } catch (error) {
                console.log("Search error:", error);
                setModalMessage(
                  "1An error occurred during the search. Please try again.",
                );
                setIsSearchError(true);
                setClickedIssue(null);
                setIssueRelativeArray([]);
                setSpecData([]);
                setStagesArray(["All"]);
              } finally {
                setIsSearchLoading(false);
              }
            }
          }
        },
        onError: () => {
          toast.error('Invalid token');
          navigate('/404Error'); // if token was error jump to our 404 Error page
        }
      });
    }

    if(queryParam && !token){
      const basicVal = [{
        id: queryParam,
        value: queryParam,
        isIssueId: !isNaN(Number(queryParam)),
      }];
      setBasicValue(basicVal);
      setClickedSimilarIssue(null);
      setSubKeySpecsMenu("design_docs");
    }
  }, // eslint-disable-next-line 
  [token]);


  // the main return area
  return (
    <div className="discover">
      <IssueArea
        issueInfo={issueInfo}
        token={token}
      />
      <SpecificationArea />
    </div>
  );
};

export default Discover;
